body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: rgb(0,65,87);
background: radial-gradient(circle, rgba(0,65,87,1) 0%, rgba(0,255,240,1) 100%);
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #44c3a1; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #34342f; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #34342f; /* creates padding around scroll thumb */
}
